import type { AsVuexStore } from '@zento-lib/components/vuex';

import type { ViewportData } from '../../../stores/application-context/types';

export const KEY = 'application-context';

export type AppContextStore = AsVuexStore<
  {
    viewport: ViewportData;
  },
  'getViewportWidth' | 'getViewportHeight' | 'getViewportScale' | 'isMobile' | 'isTablet' | 'isDesktop' | 'isServer',
  'updateViewport',
  'setViewport'
>;
